
:root{
    --blue:#1eb2a6;
    --black:#192a56;
    --light-color:#666;
    --box-shadow:0 .5rem 1.5rem rgba(0,0,0,.1);
}

.flexSB {
  display: flex;
  justify-content: space-between;
}
.icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  color: #1eb2a6;
  transition: 0.5s;
}
.icon:hover {
  cursor: pointer;
  background-color: #1eb2a6;
  color: #fff;
}

* {
  font-family: "Sans Serif", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  transition: all 0.2s linear;
}

html {
  overflow-x: hidden;
  scroll-padding-top: 5.5rem;
  scroll-behavior: smooth;
}
section{
    background-color: white;
    margin-bottom: 30px;
    margin-top: 30px;
        padding:2rem 9%;
        padding-top: 0px;
        
}



p{  
    margin-left: 20px;
    margin-bottom: 30px;
    font-size: 15px;
}




.list {
  display: flex;
  align-items: center;
  list-style: none;
}

.listItem {
  margin-right: 10px;
  font-weight: 500;
  cursor: pointer;
}
li.dropItem:hover{
  color:rgb(248, 248, 212);
  background-color: #1eb2a6;
}
.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}


/*
.card {
  width: 30%;
  padding: 10px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  margin-top: 30px;
}  
.img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin: 20px 0px;
}
.desc {
  color: #333;
  margin-bottom: 20px;
  line-height: 25px;
}

*/





.cardButton {
  border: none;
  border-radius: 10px;
  background-color: rgba(53, 16, 102, 0.678);
  padding: 10px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.post {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.postImg {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-bottom: 20px;
}

.postDesc {
  padding: 0 100px;
  font-size: 24px;
  font-weight: 300;
  color: gray;
  margin-top: 50px;
}

.postLongDesc {
  padding: 100px;
  padding-top: 50px;
  font-size: 18px;
  line-height: 24px;
}


.login {
  height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 60%;
  height: 75%;
  -webkit-box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  display: flex;
  align-items: center;
  border-radius: 20px;
}


.left,.right{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.left{
  margin-top: 50px;
}
.right{
  margin-bottom: 50px;
}
.center{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.or{
  border: 2px solid lightgray;
  border-radius: 50%;
  padding: 10px;
  color: gray;
  background-color: white;
  font-weight: bold;
}

.line{
  width: 0.5px;
  height: 70%;
  background-color: lightgray;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}

.loginButton{
  width: 150px;
  padding: 15px 25px;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
}

.google{
  background-color: #df4930;
}
.facebook{
  background-color: #507cc0;
}
.github{
  background-color: black;
}

.icon{
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

input{
  width: 200px;
  padding: 15px 20px;
  
}

.submit{
  width: 200px;
  background-color: rgba(128, 0, 128, 0.671);
  color: white;
  font-weight: bold;
  border: none;
  padding: 15px 20px;
  border-radius: 5px;
}

.loginTitle{
  position: absolute;
  top: 150px;
  color:lightgray
}

.link{
  color: inherit;
  text-decoration: none;
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  color: black;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media screen and (max-width: 992px) {
  
    
    header{
        padding:1rem 2rem;
    }

    section{
        padding:2rem;
    }
  .card {
    width: 100%;
    margin-bottom: 50px;
  }

  .wrapper {
    width: 65%;
    height: 90%;
    flex-direction: column;
  }

  .loginTitle {
    display: none;
  }

  .center {
    width: 100%;
    position: relative;
  }

  .left {
    padding: 20px;
  }

  .right {
    padding: 20px;
  }
.login {
  height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
}
  .line {
    height: 0.5px;
    width: 300px;
  }
}


footer{
    margin-top: auto;
  
}


