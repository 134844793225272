.newletter {
  background: #1eb2a6;
  padding-bottom:  0px;
  color: #fff;
  margin-bottom: 0px;
  margin-top: 0px;
}
.newletter .right,
.newletter .left {
  padding: 90px;
  margin: 0;
  display: flex;
}
.newletter h1 {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 15px;
}
.newletter .right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}
.newletter input {
  width: 100%;
  padding: 20px;
  border: none;
  outline: none;
  height: 30px;
  margin-bottom: 10x;
}
.newletter i {
  padding: 12px;
  color: #1eb2a6;
  background-color: #fff;
  
}

.footer {
    background-color: #1eb2a6;
    text-align: center;
    color: #fff;
    padding-top: 10px;
  }
  
  .footer .social-links {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
  }
  
  .footer .social-links > div {
    margin-right: 1rem;
  }
  
  .footer .company-name {
    font-weight: bold;
  }
  
  .footer .footer-content {
    text-align: center;
  }
  
  .footer .footer-content .row {
    padding-top: 1rem;
  }
  
  .footer .footer-content h6 {
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .footer .footer-content a {
    color: inherit;
    text-decoration: none;
  }
  
  .footer .copyright {
    background-color: #fff;
    color: #1eb2a6;
    padding: 1rem;
    text-align: center;
    font-weight: bold;
  }
  
  .waves {
    position:relative;
    width: 100%;
    height:15vh;
    margin-bottom:-7px; /*Fix for safari gap*/
    min-height:100px;
    max-height:150px;
  }
  .parallax > use {
    animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
  @keyframes move-forever {
    0% {
     transform: translate3d(-90px,0,0);
    }
    100% { 
      transform: translate3d(85px,0,0);
    }
  }