.nav-bar {
  background-image: url('../img/bg.png');
  background-size: cover;
  background-attachment: fixed;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: sticky;
  top: 0;
  z-index: 20;
  
      /* box-shadow:0px 0px 8px 4px rgba(0,0,0,0.15);; */
}
/* box-shadow: 5px 5px 10px rgba(0,0,0,0.5); */
.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}

.nav-logo {
  color: #fff;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  width: fit-content;
  flex-grow: 1;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
  
}

.nav-links {
  color: #fff;;
  font-weight: 600;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 3rem;
   font-weight: 500;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #1eb2a6;
}

.nav-item .active {
  color: #1eb2a6;
  border: 1px solid #1eb2a6;
}

#name{
  color: #1eb2a6;
}

.nav-icon {
   
  display: none;

}

a.login{
  border: solid;
}
@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #a8cfd6;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color: #1eb2a6;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #1eb2a6;
  }
}

#logout{
  margin-left:10px;
}

#userPic{
  margin-right:10px;
  
}

.dropdown {
  position:relative;
  display:inline-block;
}

/*sub-menu*/
.dropdown-content{
  display: none;
  position: absolute;
  background-color: #1eb2a6;
  z-index: 1;
  list-style: none;
  color:#fff
}
.dropdown:hover .dropdown-content{
  display: block;
  background-color: #1eb2a6;
  color: #fff;
}

